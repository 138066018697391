import React, { useState } from "react";
import iconClose from "../../../assets/images/icons/cross.svg";
import { useHistory, useLocation } from "react-router-dom";
import User from "../userNav";
import logoCube from "../../../assets/images/icons/load-out-cube.svg";
import { FormattedMessage } from "react-intl";
import {
  HamButton,
  LeftMenuButton,
  LeftMenuButtons,
  LeftMenuHeader,
  LeftMenuNav,
  LogoCube,
  PainelButtons,
  UserActionsMobile,
} from "./style";
import LoadOutTooltip from "../loadOutTooltip";
import {
  faUserPlus,
  faAddressCard,
  faComments,
  faHome,
  faObjectGroup,
  faUsers,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../context/AuthProvider/useAuth";

export default function LeftMenu({ toggleSideBar, setToggleSideBar }) {
  const history = useHistory();
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState([]);
  const [getDetails, setGetDetails] = useState({});
  const [configClicked, setConfigClicked] = useState(false);
  const auth = useAuth();

  let nonOperatorMenu = [
    /*{
      name: <FormattedMessage id="dashboard" />,
      icon: faHome,
      page: "/dashboard",
    },*/
    {
      name: <FormattedMessage id="chat" />,
      icon: faComments,
      page: "/chat",
    },
    {
      name: <FormattedMessage id="templates" />,
      icon: faObjectGroup,
      page: "/templates",
    },
    {
      name: <FormattedMessage id="contacts" />,
      icon: faAddressCard,
      page: "/contacts",
    },
    {
      name: <FormattedMessage id="groups" />,
      icon: faUsers,
      page: "/groups",
    },
    {
      name: <FormattedMessage id="users" />,
      icon: faUserPlus,
      page: "/users",
    },
  ];

  let operatorMenu = [
    /*{
      name: <FormattedMessage id="dashboard" />,
      icon: faHome,
      page: "/dashboard",
    },*/
    {
      name: <FormattedMessage id="chat" />,
      icon: faComments,
      page: "/chat",
    },
    {
      name: <FormattedMessage id="templates" />,
      icon: faObjectGroup,
      page: "/templates",
    },
    {
      name: <FormattedMessage id="contacts" />,
      icon: faAddressCard,
      page: "/contacts",
    },
    {
      name: <FormattedMessage id="groups" />,
      icon: faUsers,
      page: "/groups",
    }
  ];
  const noMenuPages = ["", "login", "forgot_password", "define"];

  var pathName = useLocation().pathname;

  if (noMenuPages.includes(pathName.split("/")[1])) {
    return null;
  }

  return (
    <LeftMenuNav active={toggleSideBar ? "toggleSideBar" : undefined}>
      <LeftMenuHeader>
        <img src={logoCube} alt="Ícone Hamburguer" className="ps-2" />
        <HamButton
          src={iconClose}
          alt="Ícone de Fechar"
          onClick={() => setToggleSideBar(!toggleSideBar)}
        />
      </LeftMenuHeader>
      <PainelButtons>
        <LogoCube src={logoCube} alt="Logo minimalista LoadOut" />
        <UserActionsMobile>
          <User
            details={getDetails}
            getall={country}
            setToggleSideBar={setToggleSideBar}
            mode="header"
          />
        </UserActionsMobile>
        <LeftMenuButtons>
          {(auth?.userContext?.role === 3 ? operatorMenu : nonOperatorMenu).map(
            (menu, i) => (
              <LeftMenuButton
                to={menu.page}
                active={pathName.includes(menu.page) ? "true" : undefined}
                key={i}
                onClick={() => {
                  setToggleSideBar(!toggleSideBar);
                  setConfigClicked(false);
                  console.log("close menus");
                  document
                    .getElementById("menuConfig")
                    ?.classList.add("collapsed");
                  document
                    .getElementById("params-collapse")
                    ?.classList.remove("show");
                }}
              >
                <LoadOutTooltip label={menu.name} />
                <FontAwesomeIcon
                  icon={menu.icon}
                  alt="Menu icon"
                  color={configClicked ? "white" : "black"}
                  size="2x"
                />
                {menu.name}
              </LeftMenuButton>
            )
          )}
        </LeftMenuButtons>
      </PainelButtons>
      <User
        details={getDetails}
        getall={country}
        setToggleSideBar={setToggleSideBar}
        mode="sidebar"
      />
    </LeftMenuNav>
  );
}
