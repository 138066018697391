import { useEffect, useRef, useState } from "react";
import Loading from "../../components/fragments/Loading";
import mqtt from "precompiled-mqtt";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { checkTokenValidity, useOnClickOutside, fireAlert, formatDate, useOnPasteImage } from "../../utils/utils";
import iconRefresh from "../../assets/images/icons/icon-refresh.svg";
import iconEdit from "../../assets/images/icons/icon-edit3.svg";
import iconPencil from "../../assets/images/icons/icon-edit4.svg";
import iconClose from "../../assets/images/icons/icon-delete.svg"
import { usePageVisibility } from "./usePageVisibility";
import useSound from "use-sound";
import sound from "../../assets/sounds/moon_drop.mp3";
import { FormattedMessage } from "react-intl";
import LoadOutInput from "../../components/fragments/loadOutInput";
import LoadOutButton from "../../components/fragments/loadOutButton";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import ContactItemList from "./ContactItemList";
import InfiniteScroll from "react-infinite-scroll-component";
import iconEllipsis from "../../assets/images/icons/ellipsis.svg"
import iconMarkAsUnread from "../../assets/images/icons/unread.svg"
import iconMarkAsRead from "../../assets/images/icons/read.svg"
import { ActiveBall, MessageActionsButton, MessageActionsContainer, ViewAllButton, ViewAllContainerMobile } from "../contacts/style";
import { DriverItemItem, DriverItemList, DriverItemModal, MessageActionItemItem, MessageActionItemList, MessageActionModal } from "../../styles/common";
import iconProfile from "../../assets/images/icons/empty-avatar.svg";
import { TooltipContainer } from "./style";
import { useRecoilState } from 'recoil';
import { TenantState, LineState, MqttState } from '../../state/states';
import Dynamic from "../../components/fragments/dynamic/";
import Template from "../../components/fragments/template/";
import Image from "../../components/fragments/image/";
import appTheme from "../../styles/themes";
import LoadOutPhoneInput from "../../components/fragments/loadOutPhoneInput";
import FeedBackModal from "../../components/fragments/feedBackModal";
import AddEdit from "./addEdit";

export default function Chat() {
    const auth = useAuth();
    //const [tenantData, setTenantData] = useState(auth.tenantContext);
    //const [lineData, setLineData] = useState(auth.activeLine());
    //const [mqttData, setMqttData] = useState(auth.mqttContext);
    const [tenantData, setTenantData] = useRecoilState(TenantState);
    const [lineData, setLineData] = useRecoilState(LineState);
    const [mqttData, setMqttData] = useRecoilState(MqttState);
    const isVisible = usePageVisibility();
    const [loading, setLoading] = useState(false);
    const [qtRows, setQtRows] = useState(1);
    const [pageList, setPageList] = useState(1);
    const [pageDetails, setPageDetails] = useState(1);
    const [offset, setOffset] = useState({ conversations: 30, messages: 20 });
    const [contactId, setContactId] = useState(0);
    const [details, setDetails] = useState([]);
    const [list, _setList] = useState([]);
    const [showEmoji, setShowEmoji] = useState(false);
    const [text, setText] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [contactList, setContactList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [client, setClient] = useState();
    const [payload, setPayload] = useState();
    const [chat, setChat] = useState();
    const [sent, setSent] = useState();
    const [userInfo, setUserInfo] = useState();
    const [topic, setTopic] = useState();
    const [messageActions, setMessageActions] = useState(0);
    //const [tempUnreadMarked, setTempUnreadMarked] = useState([])
    const [viewAllModal, setViewAllModal] = useState(false);
    const [contactsFound, setContactsFound] = useState(false);
    const [showDynamic, setShowDynamic] = useState(false);
    const [showTemplate, setShowTemplate] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [isGroup, setIsGroup] = useState(0);
    const [newChat, setNewChat] = useState(false);
    const [newPhone, setNewPhone] = useState("");
    const [newCode, setNewCode] = useState("+1");
    const messageRef = useRef();

    const [openModal, setOpenModal] = useState(false);

    const listRef = useRef(list);
    const setList = data => {
        listRef.current = data;
        _setList(data);
    };

    const emojiRef = useRef();
    useOnClickOutside(emojiRef, () => setShowEmoji(false));

    const imageRef = useRef();
    useOnClickOutside(imageRef, () => setShowImage(!fileName ? false : true));

    const dynamicRef = useRef();
    useOnClickOutside(dynamicRef, () => setShowDynamic(false));

    const templateRef = useRef();
    useOnClickOutside(templateRef, () => setShowTemplate(false));

    const [hasMoreScroll, setHasMoreScroll] = useState({
        conversations: true,
        messages: true
    });

    const [play] = useSound(sound, {
        interrupt: true
    });

    const [fileName, setFileName] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const pasteRef = useRef();
    useOnPasteImage(pasteRef, (e) => handlePaste(e));

    const [modalObject, setModalObject] = useState({
        type: "success",
        buttonLabel: "Ok",
        open: false,
        title: "Success",
        desc: "Description",
    });

    function handleDrop(e) {
        e.preventDefault();
        const clipboardItems = e.dataTransfer.files;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }

        const item = items[0];
        let file = new File([item], item.kind, { type: item.type, lastModified: new Date().getTime() }, 'utf-8');
        onFileChange(file);
    }

    function handlePaste(e) {
        if (e.clipboardData.getData('text')) {
            return;
        }

        e.preventDefault();
        const clipboardItems = e.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }

        const item = items[0];
        const blob = item.getAsFile();
        let file = new File([blob], item.kind, { type: item.type, lastModified: new Date().getTime() }, 'utf-8');
        onFileChange(file);
    }

    function onFileChange(file) {
        console.log(file);
        if (file?.target?.files?.length === 0) {
            return;
        }
        if (!file) {
            setFileName(undefined);
            setFile(undefined);
            return;
        }
        setFileName((window.URL || window.webkitURL).createObjectURL(file));
        setFile(file);
        setShowImage(true);
    }

    function handleText(tag) {
        const position = document.getElementById("textarea").selectionStart;
        let textBeforeCursorPosition = text.substring(0, position);
        let textAfterCursorPosition = text.substring(position, text.length);
        setText(textBeforeCursorPosition + tag + textAfterCursorPosition);
        document.getElementById("textarea")?.focus();
    }

    function handleTemplate(message) {
        setText(message);
        document.getElementById("textarea")?.focus();
    }

    function handleChange(e) {
        setText(e.target.value);
    }

    function handleShowEmoji(e) {
        e.stopPropagation();
        setShowEmoji(!showEmoji);
        setShowImage(!fileName ? false : true);
        setShowTemplate(false);
        setShowDynamic(false);
    }

    function handleShowImage() {
        setShowImage(!fileName ? !showImage : true);
        setShowEmoji(false);
        setShowTemplate(false);
        setShowDynamic(false);
    }

    function handleEmoji(e) {
        const position = document.getElementById("textarea").selectionStart;
        let textBeforeCursorPosition = text.substring(0, position);
        let textAfterCursorPosition = text.substring(position, text.length);
        setText(textBeforeCursorPosition + e.native + textAfterCursorPosition);
        setShowEmoji(false);
        document.getElementById("textarea")?.focus();
    }

    function handleShowDynamic(e) {
        setShowDynamic(!showDynamic);
        setShowImage(!fileName ? false : true);
        setShowEmoji(false);
        setShowTemplate(false);
    }

    function handleShowTemplate(e) {
        setShowTemplate(!showTemplate);
        setShowImage(!fileName ? false : true);
        setShowEmoji(false);
        setShowDynamic(false);
    }

    /*function handleShowContacts() {
        setShowEmoji(false);
        setShowImage(false);
        setShowTemplate(false);
        setShowDynamic(false);
        setContactsFound(true);
    }*/

    function handleCloseContacts() {
        setShowEmoji(false);
        setShowImage(false);
        setShowTemplate(false);
        setShowDynamic(false);
        setSearchText("");
        setContactList([]);
        setContactsFound(false);
    }

    const statusCheckChatByIdAndLineId = async () => {
        //const lastChatId = listRef?.current[0].id;
        const lastChatId = list[0]?.id;
        try {
            const { data } = await auth.getChatCheckByChatAndLineId(lastChatId, lineData.id)
            if (data.res) {
                if (data.ret[0].count > 0) {
                    play();
                    fetchDataChatRefreshList();
                    /*setModalObject({
                        type: "error",
                        buttonLabel: "Ok",
                        open: true,
                        title: "Oops...",
                        desc: "There are new conversations, please refresh your list."
                        //action: () => {
                        //    setModalObject({ ...modalObject, open: false });
                        //}
                    });*/
                }
            } else {
                fireAlert(data.message);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        }
    }

    const staticGetChatById = async () => {
        if (payload) {
            try {
                const { data } = await auth.getOneChatById(payload.chatid);
                if (data.res) {
                    //TODO: ver quando o id do group é o mesmo que o id do contact
                    var newList = list.filter(item => item.contactid !== data.ret[0].contactid);
                    newList = [data.ret[0], ...newList];
                    setList(newList);
                    if (data.ret[0].contactid === contactId) {
                        const listDetails = details.filter(item => item.id !== data.ret[0].id);
                        const newDetails = [...listDetails, data.ret[0]];
                        setDetails(newDetails);
                        //If outgoing, not set status
                        if (payload.notify) {
                            fetchDataChatStatus(data.ret[0].lineid, data.ret[0].contactid, data.ret[0].id);
                        }
                    }
                    //If incoming, notify play
                    if (payload.notify) {
                        play();
                    }
                } else {
                    fireAlert(data.message);
                }
            } catch ({ response }) {
                checkTokenValidity(response.data);
                fireAlert(response.data.message);
            }
        }
    }

    const fetchDataChatStatus = async (pLine, pContact, pChat) => {
        try {
            setLoading(true);
            await auth.getChatStatus({
                lineid: pLine,
                contactid: pContact,
                chatid: pChat
            });
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchReadUnreadMessage = async (pContact, pMessage, status) => {
        try {
            setLoading(true);
            await auth.putChatStatus({
                lineid: lineData.id,
                contactid: pContact,
                chatid: pMessage,
                status
            });

            const newDetails = details.filter((item) => {
                if (item.id === pMessage) {
                    item.readstatus = status;
                }

                return item;
            });

            setDetails([...newDetails]);

            //status === 0 ? setTempUnreadMarked([...tempUnreadMarked, pMessage]) : setTempUnreadMarked(tempUnreadMarked.filter(d => d !== pMessage));
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchDataChatList = async () => {
        try {
            setLoading(true);
            const { data } = await auth.getChatList({
                page: pageList,
                rows: offset.conversations,
                lineid: lineData.id
            });
            if (data.ret) {
                setHasMoreScroll({ ...hasMoreScroll, conversations: data.ret.length > 0 });
                const newlist = [...list, ...data.ret];
                setList(newlist);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchDataChatDetails = async (contact_id, name, phone, group) => {
        const newPageDetails = 1;
        setPageDetails(newPageDetails);
        setNewChat(false);
        handleCloseContacts();
        try {
            setLoading(true);
            const { data } = await auth.getChatDetails({
                page: newPageDetails,
                rows: offset.messages,
                lineid: lineData.id,
                contactid: contact_id,
                isgroup: group
            })
            if (data.res) {
                setHasMoreScroll({ ...hasMoreScroll, messages: data.ret.length > 0 });
                setDetails(data.ret);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setShowEmoji(false);
            setShowImage(false);
            setShowTemplate(false);
            setShowDynamic(false);
            //document.getElementById("label-upload-image").value = "";
            setFileName(undefined);
            setFile(undefined);
            setContactId(contact_id);
            setName(name);
            setPhone(phone);
            setIsGroup(group);
            setText("");
            setLoading(false);
        }
    }

    const staticFetchDataChatDetails = async () => {
        if (pageDetails <= 1) {
            return;
        }
        try {
            setLoading(true);
            const { data } = await auth.getChatDetails({
                page: pageDetails,
                rows: offset.messages,
                lineid: lineData.id,
                contactid: contactId,
                isgroup: isGroup
            });
            if (data.res) {
                setHasMoreScroll({ ...hasMoreScroll, messages: data.ret.length > 0 });
                const newDetails = [...data.ret, ...details];
                setDetails(newDetails);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchDataChatLikeSearch = async () => {
        if (searchText.length === 0) {
            setContactList([]);
            setContactsFound(false);
            return;
        }
        try {
            setLoading(true);
            setContactsFound(true);
            const { data } = await auth.getChatLike({
                like: searchText,
            });
            if (data.res && searchText.length > 0) {
                const newList = [...data.group, ...data.contact];
                setContactList(newList);
                return;
            }
            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: data.message
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }*/
            });
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);

        } finally {
            setLoading(false);
        }
    }

    const fetchDataChatRefreshList = async () => {
        setShowEmoji(false);
        setShowImage(false);
        setShowTemplate(false);
        setShowDynamic(false);
        setNewChat(false);
        handleCloseContacts();
        if (pageList > 1) {
            setList([]);
            setPageList(1);
            return;
        }
        try {
            setLoading(true)
            const { data } = await auth.getChatList({
                page: 1,
                rows: offset.conversations,
                lineid: lineData.id
            });
            if (data.res) {
                setHasMoreScroll({ ...hasMoreScroll, conversations: data.ret.length > 0 });
                setList([...data.ret]);
            }
        } catch (error) {
            checkTokenValidity(error);
            fireAlert(error);
        } finally {
            setLoading(false);
            setDetails([]);
            setContactId(0);
            setName("");
            setPhone("");
            setIsGroup(0);
            setText("");
            handleCloseContacts();
        }
    }

    useEffect(() => {
        if (searchText.trim() === "" && searchText.length > 0) return

        /*const fetchBouncer = setTimeout(() => {
            fetchDataChatLikeSearch()
        }, !!searchText ? 2000 : 0);*/

        const newText = searchText.replace(/[^\d]/g, "");

        if (newText && !isNaN(newText)) {
            if (searchText.indexOf("+") >= 0) {
                setSearchText("+" + newText);
            } else {
                setSearchText(newText);
            }
        }

        fetchDataChatLikeSearch();

        //return () => clearTimeout(fetchBouncer);
    }, [searchText]);

    useEffect(() => {
        fetchDataChatList();
    }, [pageList]);

    useEffect(() => {
        staticFetchDataChatDetails();
    }, [pageDetails]);

    useEffect(() => {
        staticGetChatById();
    }, [payload]);

    /*useEffect(() => {
        if (document.getElementsByTagName("em-emoji-picker")[0]) {
            document.getElementsByTagName("em-emoji-picker")[0].classList.add("up-emoji-picker");
        }
    }, [state])*/

    useEffect(() => {
        console.log("MQTT:", mqttData);
        console.log("TENANT:", tenantData);
        console.log("LINE:", lineData);
        if (tenantData && lineData && mqttData) {
            const options = {
                port: mqttData.port,
                clientId: "cli_" + Math.random().toString(16).substring(2, 10),
                username: mqttData.user,
                password: mqttData.pass,
                clean: true
            };
            setTopic(`${tenantData.tenant}/${lineData.id}/#`);
            setClient(mqtt.connect(mqttData.host, options));
            fetchDataChatRefreshList();
        }
    }, [tenantData, lineData, mqttData]);

    useEffect(() => {
        document.title = "LoadOut Chat - Chat";
        var userinfo = {};
        try {
            userinfo = auth.userContext;
            setUserInfo(userinfo);
        } catch (error) {
            console.log("👾 invalid token format", error);
            return true;
        }

        if (userinfo?.chgpss === 1) {
            return null;
        }

        //document.addEventListener("visibilitychange", onFocus);

        return function cleanup() {
            //document.removeEventListener("visibilitychange", onFocus);
        }

    }, []);

    //Status topic from mqtt
    useEffect(() => {
        if (chat) {
            console.log(chat);

            var newList = list.filter(item => {
                if (item.contactid === chat.contactid) {
                    item.readstatus = chat.readstatus;
                    //console.log("passou aqui");
                }
                return item;
            });
            setList([...newList]);
        }
    }, [chat]);

    //Sent topic from mqtt
    useEffect(() => {
        if (sent) {
            console.log(sent);

            if (contactId !== sent.contactid) {
                return;
            }

            var newDetails = details.filter(item => {
                if (item.id === sent.chatid) {
                    item.readstatus = sent.readstatus;
                }
                return item;
            });
            setDetails([...newDetails]);
        }
    }, [sent]);

    useEffect(() => {
        if (client && !client.connected) {
            client.on("connect", function () {
                console.log("conectou!");
                client.subscribe(topic, function (err) {
                    console.log("inscreveu-se!");
                    if (err) {
                        console.log("erro na inscrição!");
                    }
                });

            });

            client.on("disconnect", function () {
                console.log("desconectou!");
            });

            client.on("message", function (topic, message) {
                // message is Buffer
                //tenant/lineid/2/contactid/?/status = 0 or 1
                console.log(`${topic.toString()}: ${message.toString()}`);
                if (String(topic).indexOf("/chat") > 0) {
                    console.log(`Chat: ${message}`);
                    setPayload(JSON.parse(message));
                };
                if (String(topic).indexOf("/status") > 0) {
                    console.log(`Status: ${message}`);
                    setChat(JSON.parse(message));
                };
                if (String(topic).indexOf("/sent") > 0) {
                    console.log(`Sent: ${message}`);
                    setSent(JSON.parse(message));
                };
            });

            //VER quando o id do group é o mesmo id do contact

            /*client.publish("presence", "Hello mqtt", {
                retain: false,
                qos: 1
            });*/

            return function cleanup() {
                client.end();
                console.log("Desconectou!");
            }
        }
    }, [client]);

    useEffect(() => {
        //Getting the last chatid from list
        if (isVisible) {
            if (list.length === 0) {
                return;
            }
            statusCheckChatByIdAndLineId();
        } else {
            console.log("sumiu!");
        }

    }, [isVisible]);

    function handleSend() {

        if (contactId === 0 && (newCode + newPhone).length < 12) {
            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: "Please inform a valid phone number",
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }*/
            });
            return;
        } 

        setLoading(true);

        (async () => {
            const formData = new FormData();
            //if (file) {
            formData.append("media", file);
            //}
            formData.append("lineid", lineData.id);
            formData.append("contactid", contactId);
            formData.append("message", text);
            formData.append("isgroup", isGroup);
            if (contactId === 0) {
                const phoneConcat = newCode + newPhone;
                formData.append("phone", phoneConcat);
            }
            //console.log(formData);
            const { data } = await auth.postChatSend(formData).catch(err => {
                setModalObject({
                    type: "error",
                    buttonLabel: "Ok",
                    open: true,
                    title: "Oops...",
                    desc: err.response.data.message || err.request || err.message
                    /*action: () => {
                        setModalObject({ ...modalObject, open: false });
                    }*/
                });
            }).finally(() => {
                setLoading(false);
            });

            if (data.res) {
                const ret = data.ret;
                /*if (contactId !== data.contactid) {
                    return;
                }*/

                const newData = {
                    ...data,
                    id: ret.insertId,
                    userid: userInfo.id,
                    uname: userInfo.fname.concat(userInfo.lname ? " " + userInfo.lname : ""),
                    message: text,
                    readstatus: 0,
                    createdat: "Waiting ..."
                };

                const newDetails = [newData, ...details];

                setDetails(newDetails);
                setShowEmoji(false);
                setShowImage(false);
                setShowTemplate(false);
                setShowDynamic(false);
                setFileName(undefined);
                setFile(undefined);
                setText("");
                console.log(data);

                if (contactId === 0) {
                    //setContactId(ret.contactid);
                    fetchDataChatDetails(ret.contactid, ret.name, ret.phone, 0);
                    //console.log("->", ret.contactid);
                }
            } else {
                setModalObject({
                    type: "error",
                    buttonLabel: "Ok",
                    open: true,
                    title: "Oops...",
                    desc: data.message
                    /*action: () => {
                        setModalObject({ ...modalObject, open: false });
                    }*/
                });
            }
        })().catch(err => {
            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: err.response.data.message || err.request || err.message
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }*/
            });
        });
    }

    function maskNumber(number) {
        var name = "";
        switch (true) {
            case number.indexOf("+1") === 0:
                name = "(" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
                break;
            case number.indexOf("+55") === 0:
                name = "(" + number.substring(3, 5) + ") " + number.substring(5, 10) + "-" + number.substring(10);
                break;
            default:
                name = number;
        }

        return name;
    }

    /*function clearNumber(number) {
        return number.replace(/[^\d]/g, "");
    }*/

    /*function maskNumber1(number) {
        if (number.length === 0) {
          return "";
        }

        if (number.indexOf("members") > -1) {
            return number;
        }

        number = number.substring(2);
    
        number = clearNumber(number).substring(0, 10);
        var regexObj = /(\d{0,3})?(\d{0,3})?(\d{0,4})?/;
        var ret = number;
    
        if (regexObj.test(number)) {
          ret = number.replace(regexObj, "($1) $2-$3");
          var sLength = 0;
          switch (true) {
            case number.length < 1:
              break;
            case number.length < 4:
              sLength = number.length + 1;
              break;
            case number.length < 7:
              sLength = number.length + 3;
              break;
            default:
              sLength = number.length + 4;
          }
    
          ret = ret.substring(0, sLength);
        } else {
            ret = number;
        }
        
        return ret;
    }*/

    function handleNew() {
        setDetails([]);
        setContactId(0);
        setPageDetails(1);
        setName("");
        setPhone("");
        setIsGroup(0);
        setText("");
        setShowEmoji(false);
        setShowImage(false);
        setShowTemplate(false);
        setShowDynamic(false);
        handleCloseContacts();
        setNewPhone("");
        setNewChat((s) => !s);
    }

    function handleClose() {
        setNewChat(false);
        setNewPhone("");
    }

    const [windowW, setWW] = useState(window.innerWidth);
    const [windowH, setWH] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false);

    const handleSize = (e) => {
        setWW(e.target.innerWidth);
        setWH(e.target.innerHeight);
    }

    window.addEventListener('resize', handleSize)

    useEffect(() => {
        if (windowW <= 767) { setIsMobile(true) } else { setIsMobile(false) }
        return () => window.removeEventListener('resize', handleSize)
    }, [windowW])

    useEffect(() => {
        setDetails([]);
        setContactId(0);
    }, [lineData])

    function refreshData(contactObject) {
        const newName = contactObject?.fname.concat(contactObject?.lname ? " " : "",contactObject?.lname);
        setName(newName);
        const newList = list.map(item => {
            if (item.contactid === contactObject.id) {
                item.name = newName;
            }
            return item;
        });
        setList(newList);
    }

    useEffect(() => {
        var newNumber = searchText;
        if (searchText.indexOf("+1") >= 0) {
            newNumber.replace("+1", "");
        }

        if (searchText.length === 10 || searchText.length === 12) {
            if (!isNaN(searchText)) {
                if (contactList.length === 0) {
                    setContactList([{
                        new: true,
                        isgroup: false,
                        name: "New Contact",
                        phone: searchText.indexOf("+1") >= 0 ? searchText : "+1" + searchText,
                        createdat: "2024-05-20 00:00:00"
                    }]);
                }
            }
        }
    }, [contactList])

    function fetchNewChat() {
        setNewCode("+1");
        setNewPhone(searchText.replace("+1", ""));
        setDetails([]);
        setContactId(0);
        setNewChat(true);
        handleCloseContacts();
    }

    return (
        <>
            <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
            <AddEdit
                openModal={openModal}
                setOpenModal={setOpenModal}
                id={contactId}
                refreshData={refreshData}
            />
            <div style={{ width: isMobile ? '100%' : `${windowW <= 900 ? windowW * 0.975 : windowW * 0.90}`, position: "fixed", paddingTop: "50px" }}>
                <Loading visible={loading} />
                <div className="h-90">
                    <div className="col">
                        <div className="col-sm-12 justify-content-center">
                            <div className="card" id="chat3" style={{ borderRadius: "15px" }}>
                                <div className="card-body">
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-md-4 col-lg-5 col-xl-4 mb-2 mb-md-0 pt-0 pt-md-5">
                                            <div className="p-lg-1" style={{ height: isMobile ? '100%' : "calc(100vh - 160px)" }}>
                                                {isMobile ?
                                                    (!!contactId ?
                                                        <>
                                                            <div className="d-flex border-bottom">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 24 24" onClick={() => {
                                                                        setDetails([]);
                                                                        setContactId(0);
                                                                        setPageDetails(1);
                                                                    }}>
                                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                                    </svg>

                                                                </div>
                                                                <div className="ml-3 mt-1 mb-2 nowrap w-100">
                                                                    <div className="d-flex flex-row justify-content-between">
                                                                        <div>
                                                                            <span className="fw-bold small mt-2" style={{ fontSize: 14 }}>{name ?? "N/A"}</span><br />
                                                                            <span className="small" style={{ fontSize: 12 }}>{phone ? maskNumber(phone) : ""}</span>
                                                                        </div>
                                                                        {name && !isGroup && <img onClick={() => setOpenModal(true)} src={iconPencil} alt="Edit Contact Icon" title="Edit Contact" className="btn btn-icon p-0" />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col d-flex flex-row p-0 justify-content-between m-2">
                                                                <div>
                                                                    <p className="fw-bold h3">Chats</p>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <img onClick={fetchDataChatRefreshList} src={iconRefresh} alt="Refresh Icon" title="Refresh List" className="btn btn-icon mt-1 p-0" />
                                                                    </div>
                                                                    {/*<div>
                                                                        <img onClick={handleNew} src={iconEdit} alt="New Message Icon" title="New Message" className="btn btn-icon p-0" />
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                            {!newChat && isMobile &&
                                                                <div className="input-group mb-0 p-2 pe-3" style={{ marginTop: -15 }}>
                                                                    <LoadOutInput
                                                                        value={searchText}
                                                                        setSearch={setSearchText}
                                                                        type="find"
                                                                        onChange={(e) => setSearchText(e.target.value)}
                                                                        highlight={<FormattedMessage id="search_new" />}
                                                                    />
                                                                </div>
                                                            }
                                                            {newChat && isMobile &&
                                                                <div className="input-group mb-0 p-2 pe-3" style={{ marginTop: -15 }}>
                                                                    <LoadOutPhoneInput value={newPhone} onChange={setNewPhone} setCode={setNewCode} close={handleClose} showClose={true} />
                                                                </div>
                                                            }
                                                            {contactsFound ? <div id="scroll-contact" data-mdb-perfect-scrollbar="true" style={{ height: isMobile ? windowH * 0.60 : "100%", overflow: "scroll" }}>
                                                                <ul className="list-unstyled">
                                                                    {contactList?.map((contact, index) => {
                                                                        return (
                                                                            <li className="ms-2 mb-2">
                                                                                <ContactItemList key={index} details={contact} onClick={contact?.new ? fetchNewChat : fetchDataChatDetails} />
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                                :
                                                                (!newChat && <div id="scroll-conversation" data-mdb-perfect-scrollbar="true" style={{ height: isMobile ? windowH * 0.75 : "100%", overflow: "scroll", marginLeft: 8 }}>
                                                                    <ul className="list-unstyled">
                                                                        <InfiniteScroll
                                                                            scrollableTarget="scroll-conversation"
                                                                            dataLength={list.length}
                                                                            next={() => {
                                                                                setPageList(pageList + 1)
                                                                            }}
                                                                            hasMore={hasMoreScroll.conversations}
                                                                            endMessage={<p style={{ textAlign: "center" }}>End of Conversations</p>}
                                                                        >
                                                                            {list?.map((contact, index) => {
                                                                                return (<li
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: `${contact?.readstatus === 0 ? "1px solid #640180" : "transparent"}`
                                                                                    }}
                                                                                    className={`p-3 mb-2 rounded-3${contact.contactid === contactId && contact.isgroup === isGroup ? " bg-secondary bg-gradient text-white" : " bg-light bg-gradient"}`}
                                                                                    onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)}
                                                                                    key={index}>
                                                                                    <div className="d-flex flex-row justify-content-between align-items-top">
                                                                                        <div>
                                                                                            <small className="fw-bold" style={{ fontSize: 13 }}>{(contact?.name ?? "N/A")}</small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small className="text-end" style={{ fontSize: 11 }}>{formatDate(contact?.createdat)}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "block" : "none" }}>{contact?.message?.length > 100 ? contact?.message?.substring(0, 100) + "..." : contact?.message}</div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "none" : "block" }}>(image)</div>
                                                                                    <div className="d-flex justify-content-between" style={{ fontSize: 11 }}>
                                                                                        <div>Last replied by {contact?.uname ? contact?.uname : contact?.name ? contact?.name : "N/A"}</div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"} className={contact?.readstatus === 0 ? "visible" : "invisible"}>
                                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </li >)
                                                                            })}
                                                                            {list?.map((contact, index) => {
                                                                                return (<li
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: `${contact?.readstatus === 0 ? "1px solid #640180" : "transparent"}`
                                                                                    }}
                                                                                    className={`p-3 mb-2 rounded-3${contact.contactid === contactId && contact.isgroup === isGroup ? " bg-secondary bg-gradient text-white" : " bg-light bg-gradient"}`}
                                                                                    onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)}
                                                                                    key={index}>
                                                                                    <div className="d-flex flex-row justify-content-between align-items-top">
                                                                                        <div>
                                                                                            <small className="fw-bold" style={{ fontSize: 13 }}>{(contact?.name ?? "N/A")}</small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small className="text-end" style={{ fontSize: 11 }}>{formatDate(contact?.createdat)}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "block" : "none" }}>{contact?.message?.length > 100 ? contact?.message?.substring(0, 100) + "..." : contact?.message}</div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "none" : "block" }}>(image)</div>
                                                                                    <div className="d-flex justify-content-between" style={{ fontSize: 11 }}>
                                                                                        <div>Last replied by {contact?.uname ? contact?.uname : contact?.name ? contact?.name : "N/A"}</div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"} className={contact?.readstatus === 0 ? "visible" : "invisible"}>
                                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </li >)
                                                                            })}
                                                                            {list?.map((contact, index) => {
                                                                                return (<li
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: `${contact?.readstatus === 0 ? "1px solid #640180" : "transparent"}`
                                                                                    }}
                                                                                    className={`p-3 mb-2 rounded-3${contact.contactid === contactId && contact.isgroup === isGroup ? " bg-secondary bg-gradient text-white" : " bg-light bg-gradient"}`}
                                                                                    onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)}
                                                                                    key={index}>
                                                                                    <div className="d-flex flex-row justify-content-between align-items-top">
                                                                                        <div>
                                                                                            <small className="fw-bold" style={{ fontSize: 13 }}>{(contact?.name ?? "N/A")}</small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small className="text-end" style={{ fontSize: 11 }}>{formatDate(contact?.createdat)}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "block" : "none" }}>{contact?.message?.length > 100 ? contact?.message?.substring(0, 100) + "..." : contact?.message}</div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "none" : "block" }}>(image)</div>
                                                                                    <div className="d-flex justify-content-between" style={{ fontSize: 11 }}>
                                                                                        <div>Last replied by {contact?.uname ? contact?.uname : contact?.name ? contact?.name : "N/A"}</div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"} className={contact?.readstatus === 0 ? "visible" : "invisible"}>
                                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </li >)
                                                                            })}
                                                                        </InfiniteScroll >
                                                                    </ul >
                                                                </div >)
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col d-flex flex-row p-0 justify-content-between m-2">
                                                                <div>
                                                                    <p className="fw-bold h3">Chats</p>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <img onClick={fetchDataChatRefreshList} src={iconRefresh} alt="Refresh Icon" title="Refresh List" className="btn btn-icon mt-1 p-0" />
                                                                    </div>
                                                                    {/*<div>
                                                                        <img onClick={handleNew} src={iconEdit} alt="New Message Icon" title="New Message" className="btn btn-icon p-0" />
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                            <div className="input-group mb-0 p-2 pe-3" style={{ marginTop: -15 }}>
                                                                <LoadOutInput
                                                                    value={searchText}
                                                                    setSearch={setSearchText}
                                                                    type="find"
                                                                    onChange={(e) => setSearchText(e.target.value)}
                                                                    highlight={<FormattedMessage id="search_new" />}
                                                                />
                                                            </div>
                                                            {contactsFound ? <div id="scroll-contact" data-mdb-perfect-scrollbar="true" style={{ height: "100%", overflow: "scroll" }}>
                                                                <ul className="list-unstyled">
                                                                    {contactList?.map((contact, index) => {
                                                                        return (
                                                                            <li className="ms-2 mb-2">
                                                                                <ContactItemList key={index} details={contact} onClick={contact?.new ? fetchNewChat : fetchDataChatDetails} />
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                                :
                                                                <div id="scroll-conversation" data-mdb-perfect-scrollbar="true" style={{ height: "100%", overflow: "scroll", marginLeft: 8 }}>
                                                                    <ul className="list-unstyled">
                                                                        <InfiniteScroll

                                                                            scrollableTarget="scroll-conversation"
                                                                            dataLength={list.length}
                                                                            next={() => {
                                                                                setPageList(pageList + 1)
                                                                            }}
                                                                            hasMore={hasMoreScroll.conversations}
                                                                            endMessage={<p style={{ textAlign: "center" }}>End of Conversations</p>}
                                                                        >
                                                                            {list?.map((contact, index) => {
                                                                                return (<li
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: `${contact?.readstatus === 0 ? "1px solid #640180" : "transparent"}`
                                                                                    }}
                                                                                    className={`p-3 mb-2 rounded-3${contact.contactid === contactId && contact.isgroup === isGroup ? " bg-secondary bg-gradient text-white" : " bg-light bg-gradient"}`}
                                                                                    onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)}
                                                                                    key={index}>
                                                                                    <div className="d-flex flex-row justify-content-between align-items-top">
                                                                                        <div>
                                                                                            <small className="fw-bold" style={{ fontSize: 13 }}>{(contact?.name ?? "N/A")}</small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small className="text-end" style={{ fontSize: 11 }}>{formatDate(contact?.createdat)}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "block" : "none" }}>{contact?.message?.length > 100 ? contact?.message?.substring(0, 100) + "..." : contact?.message}</div>
                                                                                    <div className="small" style={{ fontSize: 13, display: contact.message ? "none" : "block" }}>(image)</div>
                                                                                    <div className="d-flex justify-content-between" style={{ fontSize: 11 }}>
                                                                                        <div>Last replied by {contact?.uname ? contact?.uname : contact?.name ? contact?.name : "N/A"}</div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"} className={contact?.readstatus === 0 ? "visible" : "invisible"}>
                                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </li>)
                                                                            })}
                                                                        </InfiniteScroll>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                }


                                            </div >
                                        </div >
                                        <div className="col" style={{ height: isMobile ? 'calc(100vh - 255px)' : "calc(100vh - 245px)" }}>
                                            <div>
                                                {newChat && !isMobile ?
                                                    <div className="mt-1 p-2 border-bottom">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-row pt-3 pt-md-0">
                                                                <LoadOutPhoneInput value={newPhone} onChange={setNewPhone} setCode={setNewCode} close={handleClose} showClose={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    (!isMobile && <div className="mt-4 p-2 border-bottom">
                                                        <div className="d-flex flex-row w-100 justify-content-between">
                                                            <div className="pt-3 pt-md-0">
                                                                <p className="fw-bold mb-0">{(name ?? "N/A").concat(phone ? " • " + maskNumber(phone) : "")}</p>
                                                            </div>
                                                            <div>
                                                                {name && !isGroup && <img onClick={() => setOpenModal(true)} src={iconPencil} alt="Edit Contact Icon" title="Edit Contact" className="btn btn-icon p-0" />}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                            <div id="scroll-messages" className={isMobile ? "ps-1" : ''} data-mdb-perfect-scrollbar="true"
                                                style={{ position: "relative", height: isMobile ? "100%" : '100%', overflow: "scroll", display: "flex", flexDirection: "column-reverse" }}>
                                                <InfiniteScroll
                                                    scrollableTarget="scroll-messages"
                                                    dataLength={details.length}
                                                    next={() => {
                                                        setPageDetails(pageDetails + 1);
                                                    }}
                                                    style={{ display: "flex", flexDirection: "column-reverse" }}
                                                    inverse={true}
                                                    hasMore={hasMoreScroll.messages}
                                                    endMessage={<p style={{ textAlign: "center" }}>End of Messages</p>}
                                                >
                                                    {details?.sort((a, b) => new Date(b.createdat).getTime() - new Date(a.createdat).getTime()).map((detailsItem, index) => {
                                                        return detailsItem.userid === 0 ?
                                                            (
                                                                <>
                                                                    <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <p style={{ fontSize: 10 }} className="small mb-2 rounded-3 text-muted">{formatDate(detailsItem.createdat)}</p>
                                                                            {//tempUnreadMarked.includes(detailsItem.id)
                                                                                detailsItem.readstatus === 0 && <div className="d-flex ms-1 ms-auto"><img src={iconMarkAsUnread} width="20" height="20" alt="Icon Tic" /></div>}
                                                                            <MessageActionsContainer>
                                                                                <MessageActionsButton
                                                                                    active={viewAllModal}
                                                                                    onClick={() => {
                                                                                        setViewAllModal(!viewAllModal)
                                                                                        setMessageActions(detailsItem.id)
                                                                                    }}
                                                                                >
                                                                                    <img src={iconEllipsis} width="15" height="15" alt="Ellipses" style={{ cursor: "pointer" }} className="mx-1" />
                                                                                </MessageActionsButton>
                                                                                <MessageActionModal ref={messageRef} active={viewAllModal && messageActions === detailsItem.id ? "true" : undefined}>
                                                                                    <MessageActionItemList>
                                                                                        {
                                                                                            //!tempUnreadMarked.includes(detailsItem.id)
                                                                                            detailsItem.readstatus === 1 ?
                                                                                                <MessageActionItemItem
                                                                                                    active={undefined}
                                                                                                    onClick={() => {
                                                                                                        setViewAllModal(!viewAllModal);
                                                                                                        fetchReadUnreadMessage(detailsItem.contactid, detailsItem.id, 0)
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="d-flex w-auto">
                                                                                                        <img className="mt-1" src={iconMarkAsUnread} height="20" alt="Icon Tic" />
                                                                                                        <span style={{ fontSize: 10, color: "#640180" }} >Mark as Unread</span>
                                                                                                    </div>
                                                                                                </MessageActionItemItem>
                                                                                                :
                                                                                                <MessageActionItemItem
                                                                                                    active={undefined}
                                                                                                    onClick={() => {
                                                                                                        setViewAllModal(!viewAllModal);
                                                                                                        fetchReadUnreadMessage(detailsItem.contactid, detailsItem.id, 1)
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="d-flex">
                                                                                                        <img className="mt-1" src={iconMarkAsRead} width="20" height="20" alt="Icon Tic" />
                                                                                                        <span style={{ fontSize: 10, color: "#640180" }}>Mark as Read</span>
                                                                                                    </div>
                                                                                                </MessageActionItemItem>
                                                                                        }
                                                                                    </MessageActionItemList>
                                                                                </MessageActionModal>
                                                                            </MessageActionsContainer>
                                                                        </div>
                                                                    </div>
                                                                    {detailsItem.message &&
                                                                        <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "text"}>
                                                                            <div className={"w-auto"}>
                                                                                <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">{detailsItem.message}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("image") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "image"}>
                                                                            <div style={{ maxWidth: "25%" }}>
                                                                                <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                                                                                    <a href={detailsItem?.media} target="_blank" rel="noopener noreferrer">
                                                                                        <img className="img-fluid rounded-3" src={detailsItem?.media} alt={detailsItem?.mediatype} />
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("audio") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "image"}>
                                                                            <div style={{ maxWidth: "25%" }}>
                                                                                <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                                                                                    <audio controls><source src={detailsItem?.media} /></audio>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("video") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "text"}>
                                                                            <div className={"w-auto"}>
                                                                                <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">(video not supported)</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <div className="d-flex flex-row justify-content-end flex-end" key={index}>
                                                                        <div className={"float-end w-auto"}>
                                                                            <p style={{ fontSize: 10 }} className="small mb-2 rounded-3 text-muted text-end">{detailsItem.uname + " • " + formatDate(detailsItem.createdat)}</p>
                                                                        </div>
                                                                    </div>
                                                                    {detailsItem.message &&
                                                                        <div className="d-flex flex-row justify-content-end flex-end" key={index + "text"}>
                                                                            <div className={"float-end w-auto"}>
                                                                                <p style={{ backgroundColor: "#e7e7e7" /* #FDF8FF */ }} className="small py-2 px-3 me-0 mb-1 rounded-3 text-end">{detailsItem.message}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("image") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "image"}>
                                                                            <div style={{ maxWidth: "25%" }}>
                                                                                <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                                                                                    <a href={detailsItem?.media} target="_blank" rel="noopener noreferrer">
                                                                                        <img className="img-fluid rounded-3" src={detailsItem?.media} alt={detailsItem?.mediatype} />
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("audio") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "image"}>
                                                                            <div style={{ maxWidth: "25%" }}>
                                                                                <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                                                                                    <audio controls><source src={detailsItem?.media} /></audio>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {detailsItem.media && detailsItem?.mediatype?.indexOf("video") >= 0 &&
                                                                        <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "text"}>
                                                                            <div className={"w-auto"}>
                                                                                <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">(video not supported)</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                    })}
                                                </InfiniteScroll>
                                            </div>

                                            {(!!contactId || newChat) &&
                                                <div className={isMobile ? 'bottom-0 position-fixed col-11 mb-2' : ''}>
                                                    <form className="chat-form me-3 rounded-3 pr-2 w-100" style={{ backgroundColor: "#F6F9FB" }} data-emoji-form="" onSubmit={(e) => {
                                                        e.preventDefault();
                                                        //postDataChatCreate()
                                                    }}>
                                                        <div className="row align-items-center gx-0">
                                                            <div className="col-sm-12 col-md-12">
                                                                <div className="input-group">
                                                                    <span ref={emojiRef} style={{ visibility: showEmoji ? 'visible' : 'hidden', position: "absolute", width: "350px", top: "-440px", zIndex: "101" }}>
                                                                        <Picker data={data} theme="light" OnClickOutside={() => showEmoji && setShowEmoji(false)} previewPosition="none" maxFrequentRows="2" onEmojiSelect={emoji => handleEmoji(emoji)} />
                                                                    </span>
                                                                    <span ref={imageRef} style={{ display: showImage ? 'block' : 'none', position: "absolute", top: "-355px", zIndex: "100" }}>
                                                                        <Image openModal={showImage} setOpenModal={setShowImage} fileName={fileName} onFileChange={onFileChange} />
                                                                    </span>
                                                                    <span ref={templateRef} className="m-0" style={{ display: showTemplate ? 'block' : 'none', position: "absolute", top: "-355px", zIndex: "100" }}>
                                                                        <Template openModal={showTemplate} setOpenModal={setShowTemplate} setLoading={setLoading} setText={handleTemplate} />
                                                                    </span>
                                                                    <span ref={dynamicRef} className="m-0" style={{ display: showDynamic ? 'block' : 'none', position: "absolute", top: "-355px", zIndex: "100" }}>
                                                                        <Dynamic openModal={showDynamic} setOpenModal={setShowDynamic} setLoading={setLoading} setText={handleText} />
                                                                    </span>
                                                                    <textarea ref={pasteRef} onDrop={handleDrop} onPaste={handlePaste} id="textarea" className="form-control border-0  shadow-none p-2 px-3"
                                                                        value={text}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter" && !e.shiftKey && (text.trim().length > 0 || fileName)) {
                                                                                e.preventDefault();
                                                                                handleSend();
                                                                            }
                                                                            //console.log(e.key);
                                                                            /*if (e.key === "Enter" && qtRows < 5) setQtRows(qtRows + 1)
                                                                            if (text.split("\n").length < qtRows) {
                                                                                setQtRows(qtRows - 1)
                                                                            }*/
                                                                        }}
                                                                        onChange={(e) => handleChange(e)}
                                                                        placeholder="Type a message or paste/drop an image"
                                                                        rows={2}
                                                                        data-emoji-input=""
                                                                        data-autosize="true"
                                                                        style={{ width: "100%", backgroundColor: "#F6F9FB", overflow: "hidden", overflowWrap: "break-word", resize: "none", minHeight: "45px", maxHeight: "250px", overflowY: "auto", borderRadius: "15px" }}>
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="row d-flex justify-content-between">
                                                        <div className="col">
                                                            <div className="col d-flex mt-2">
                                                                <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                                                                    <div className="tooltiptext"><FormattedMessage id="emojis" /></div>
                                                                    <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowEmoji}>
                                                                        <svg viewBox="0 0 24 24" width="24" height="24">
                                                                            <circle cx="12" cy="12.5" r="10" fill="transparent"></circle>
                                                                            <path fill={showEmoji ? appTheme.colors.purple[500] : ""} d="M12 23.14a10.75 10.75 0 1 1 10.75-10.75A10.77 10.77 0 0 1 12 23.14zm0-20a9.25 9.25 0 1 0 9.25 9.25A9.26 9.26 0 0 0 12 3.14zm5.36 12.34a.74.74 0 0 0-.27-1 .76.76 0 0 0-1 .27 4.68 4.68 0 0 1-8.12 0 .75.75 0 0 0-1-.27.74.74 0 0 0-.27 1 6.18 6.18 0 0 0 10.72 0zm-2.73-5a.88.88 0 1 0 .87-.87.88.88 0 0 0-.87.83zm-7 0a.88.88 0 1 0 1.75 0 .88.88 0 0 0-1.75 0zm3.17-.29"></path>
                                                                        </svg>
                                                                    </span>
                                                                </TooltipContainer>
                                                                <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                                                                    <div className="tooltiptext"><FormattedMessage id="images" /></div>
                                                                    <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowImage}>
                                                                        <svg viewBox="0 0 24 24" height="24" width="24">
                                                                            <path fill="transparent" d="M3.5 3.5h17v17h-17z"></path>
                                                                            <path fill={showImage ? appTheme.colors.purple[500] : ""} d="M8.5 10.75A2.261 2.261 0 0 1 6.25 8.5 2.261 2.261 0 0 1 8.5 6.25a2.261 2.261 0 0 1 2.25 2.25 2.261 2.261 0 0 1-2.25 2.25zm0-3a.754.754 0 0 0-.75.75c0 .411.339.75.75.75s.75-.339.75-.75a.763.763 0 0 0-.75-.75zM19 2.25H5A2.763 2.763 0 0 0 2.25 5v14A2.763 2.763 0 0 0 5 21.75h14A2.763 2.763 0 0 0 21.75 19V5A2.763 2.763 0 0 0 19 2.25zM3.75 19V5c0-.686.564-1.25 1.25-1.25h14c.686 0 1.25.564 1.25 1.25v8.19l-3.72-3.72a.753.753 0 0 0-1.06 0L4.74 20.2a1.234 1.234 0 0 1-.99-1.2zM19 20.25H6.81L16 11.06l4.25 4.25V19c0 .686-.564 1.25-1.25 1.25z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </TooltipContainer>
                                                                <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                                                                    <div className="tooltiptext"><FormattedMessage id="templates" /></div>
                                                                    <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowTemplate}>
                                                                        <svg viewBox="0 0 24 24" width="24" height="24">
                                                                            <path fill="transparent" d="M8.5 2h7v4h-7z"></path>
                                                                            <path fill="transparent" d="M4.5 4.5h15v17h-15z"></path>
                                                                            <path fill={showTemplate ? appTheme.colors.purple[500] : ""} d="M15 1.25A1.76 1.76 0 0 1 16.75 3v.25H18A2.75 2.75 0 0 1 20.75 6v14A2.75 2.75 0 0 1 18 22.75H6A2.75 2.75 0 0 1 3.25 20V6A2.75 2.75 0 0 1 6 3.25h1.25V3A1.76 1.76 0 0 1 9 1.25h6zm3 20c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25h-1.25V5A1.76 1.76 0 0 1 15 6.75H9A1.76 1.76 0 0 1 7.25 5v-.25H6c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h12zM14.486 9.5a.764.764 0 1 1 0 1.527H12.88v5.749a.873.873 0 0 1-1.747 0v-5.749H9.514a.764.764 0 1 1 0-1.527h4.972zM15 5.25a.25.25 0 0 0 .25-.25V3a.25.25 0 0 0-.25-.25H9a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h6z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </TooltipContainer>
                                                                <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                                                                    <div className="tooltiptext"><FormattedMessage id="dynamic" /></div>
                                                                    <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowDynamic}>
                                                                        <svg viewBox="0 0 24 24" width="24" height="24">
                                                                            <rect fill="transparent" x="4" y="3" width="16" height="18.2"></rect>
                                                                            <path fill={showDynamic ? appTheme.colors.purple[500] : ""} d="M8.34,22H4a.8.8,0,0,1-.8-.8V2.94A.79.79,0,0,1,4,2.15H8.34a.79.79,0,0,1,.8.79.8.8,0,0,1-.8.8H4.76V20.4H8.34a.79.79,0,0,1,.8.79A.8.8,0,0,1,8.34,22Zm12.5-.8V2.94a.79.79,0,0,0-.8-.79H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8h3.58V20.4H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8H20A.8.8,0,0,0,20.84,21.19Z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </TooltipContainer>
                                                                <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                                                                    <div className="tooltiptext"><FormattedMessage id="translator" /></div>
                                                                    <span style={{ width: "24px", height: "24px", fill: "#6e747c" }}>
                                                                        <svg viewBox="0 0 24 24" height="24" width="24">
                                                                            <circle cx="12" cy="12" r="10" fill="transparent"></circle>
                                                                            <path d="M12 1.25A10.75 10.75 0 1 0 22.75 12 10.76 10.76 0 0 0 12 1.25zm9.21 10H16.7a16 16 0 0 0-3-8.34 9.27 9.27 0 0 1 7.51 8.34zM8.8 12.75h6.4a14.56 14.56 0 0 1-3.2 8.1 14.47 14.47 0 0 1-3.2-8.1zm0-1.5a14.56 14.56 0 0 1 3.2-8.1 14.47 14.47 0 0 1 3.2 8.1zm1.51-8.34a16.08 16.08 0 0 0-3 8.34H2.79a9.27 9.27 0 0 1 7.52-8.34zm-7.52 9.84H7.3a16 16 0 0 0 3 8.34 9.27 9.27 0 0 1-7.51-8.34zm10.9 8.34a16.08 16.08 0 0 0 3-8.34h4.51a9.27 9.27 0 0 1-7.51 8.34z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </TooltipContainer>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-2 w-auto">
                                                            <LoadOutButton
                                                                disabled={text.trim().length === 0 && !fileName}
                                                                onClick={handleSend}
                                                                icon="send"
                                                                label={"Send"}
                                                                size="xs"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
}