import React from "react";
import LoadOutButton from "../loadOutButton";
import {
  TablePaginationButtons,
  TablePaginationContainer,
  TablePaginationLabel,
} from "./style";
import LoadOutDropdown from "../loadOutDropdown";

export default function TablePagination({
  nextAction,
  previousAction,
  currentPage,
  totalPages,
  perPage,
  setPerPage
}) {

  const data = [ 5, 25, 50, 100 ];

  return (
    <TablePaginationContainer>
      <LoadOutButton
        icon="arrowLeft"
        color="border"
        disabled={currentPage <= 1}
        onClick={previousAction}
      />
      <TablePaginationLabel>
        Page <span>{currentPage}</span> of <span>{totalPages}</span>
      </TablePaginationLabel>
      <LoadOutButton
        icon="arrowRight"
        color="border"
        disabled={currentPage === totalPages || totalPages <= 0}
        onClick={nextAction}
      />
      <TablePaginationLabel>
        <LoadOutDropdown data={data} value={perPage} setValue={setPerPage} fieldStyle={{ width: "auto", minWidth: "90px" }} />
      </TablePaginationLabel>
      <TablePaginationButtons>
        <LoadOutButton
          label="Previous"
          color="border"
          disabled={currentPage <= 1}
          onClick={previousAction}
        />
        <LoadOutButton
          label="Next"
          color="border"
          disabled={currentPage === totalPages || totalPages <= 0}
          onClick={nextAction}
        />
      </TablePaginationButtons>
    </TablePaginationContainer>
  );
}
