import { useState } from "react";
import appTheme from "../../../styles/themes";
import { ButtonField } from "./style";

const LoadOutButton = ({
  label,
  type,
  icon,
  disabled,
  visible = true,
  onClick,
  color,
  size,
}) => {
  const buttonIcons = {
    download: (
      <svg
        width="21"
        height="20"
        stroke={`${appTheme.colors.purple[500]}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.75 12.5v3.333a1.666 1.666 0 0 1-1.667 1.667H4.417a1.667 1.667 0 0 1-1.667-1.667V12.5m3.333-4.167L10.25 12.5m0 0 4.167-4.167M10.25 12.5v-10"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    home: (
      <svg
        width="17"
        height="16"
        fill="none"
        stroke={`${appTheme.colors.gray[500]}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25 14.667V8h4v6.667M2.25 6l6-4.667 6 4.667v7.333a1.334 1.334 0 0 1-1.333 1.334H3.583a1.334 1.334 0 0 1-1.333-1.334V6Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    lock: (
      <svg
        width="17"
        height="16"
        fill="none"
        stroke={`${appTheme.colors.gray[500]}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.917 7.333V4.667a3.333 3.333 0 0 1 6.666 0v2.666m-8 0h9.334c.736 0 1.333.597 1.333 1.334v4.666c0 .737-.597 1.334-1.333 1.334H3.583a1.333 1.333 0 0 1-1.333-1.334V8.667c0-.737.597-1.334 1.333-1.334Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    exit: (
      <svg
        width="17"
        height="16"
        fill="none"
        stroke={`${appTheme.colors.gray[500]}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25 14H3.583a1.334 1.334 0 0 1-1.333-1.333V3.333A1.333 1.333 0 0 1 3.583 2H6.25m4.667 9.333L14.25 8m0 0-3.333-3.333M14.25 8h-8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    send: (
      <svg
        width="21"
        height="20"
        stroke={`${appTheme.colors.purple[500]}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m18.583 1.667-9.166 9.166m9.166-9.166L12.75 18.333l-3.333-7.5m9.166-9.166L1.917 7.5l7.5 3.333"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    send2: (
      <svg
        width="21"
        height="21"
        fill="none"
        stroke="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m3.25 9.501 15.833-7.5-7.5 15.833-1.666-6.666L3.25 9.5Z"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    arrowLeft: (
      <svg
        width="20"
        height="20"
        stroke="#344054"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 10H4.167m0 0L10 15.832M4.167 9.999 10 4.166"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    arrowRight: (
      <svg
        width="20"
        height="20"
        stroke="#344054"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.167 10h11.666m0 0L10 4.165m5.833 5.833L10 15.833"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    add: (
      <svg
        width="20"
        height="20"
        stroke="#8422CF"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 4.501v11.667m-5.833-5.834h11.666"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    print: (
      <svg
        width="21"
        height="21"
        fill="none"
        stroke="#344054"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <path
            d="M5.062 8.269V2.436h10v5.833m-10 7.5H3.395a1.667 1.667 0 0 1-1.666-1.667V9.936a1.667 1.667 0 0 1 1.666-1.667H16.73a1.667 1.667 0 0 1 1.666 1.667v4.166a1.666 1.666 0 0 1-1.666 1.667h-1.667m-10-3.334h10v6.667h-10v-6.667Z"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(.063 .769)"
              d="M0 0h20v20H0z"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    trash: (
      <svg
        width="21"
        height="21"
        fill="none"
        stroke="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.563 5.769h1.666m0 0h13.334m-13.334 0v11.667a1.667 1.667 0 0 0 1.667 1.666h8.333a1.667 1.667 0 0 0 1.667-1.666V5.769H4.229Zm2.5 0V4.102a1.667 1.667 0 0 1 1.667-1.666h3.333a1.667 1.667 0 0 1 1.667 1.666V5.77m-5 4.167v5m3.333-5v5"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    edit: (
      <svg
        width="21"
        height="21"
        fill="none"
        stroke="#1D2939"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <path
            d="M14.203 3.168A2.357 2.357 0 0 1 17.537 6.5l-11.25 11.25-4.584 1.25 1.25-4.583 11.25-11.25Z"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(.035 .668)"
              d="M0 0h20v20H0z"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  return (
    <ButtonField
      style={{ display: visible ? "" : "none", justifyContent: "center", width: !isNaN(size) ? size + "px" : "auto"}}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      color={color}
      size={size}
    >
      {icon && buttonIcons[`${icon}`]}
      {label}
    </ButtonField>
  );
};

export default LoadOutButton;
